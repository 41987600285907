import { SiteMetadata } from '~/types'
import { graphql } from 'gatsby'
import { useStaticQuery } from 'gatsby'

export default () =>
    useStaticQuery(graphql`
        query SiteMetadata {
            sanitySiteMetadata {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
        }
    `).sanitySiteMetadata as SiteMetadata
